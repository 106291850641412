import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import { VueWindowSizePlugin } from 'vue-window-size/option-api';
import { createGtm } from '@gtm-support/vue-gtm';
import VueCookies from 'vue3-cookies'
import { useCookies } from "vue3-cookies";
import { createHead } from '@unhead/vue'
import { InferSeoMetaPlugin } from '@unhead/addons'
import { createPinia } from 'pinia'


const gtm = createGtm(
{
    id: 'GTM-WW6DPH4',
    defer: false,
    compatibility: false,
    nonce: '2726c7f26c',
    enabled: true,
    debug: false,
    loadScript: true,
    vueRouter: router,
    ignoredViews: [],
    trackOnNextTick: false,
});

const app = createApp(App);
const pinia = createPinia();

app.use(router);
app.use(VueCookies, {expireTimes: "1y", });
app.use(ElementPlus);
app.use(VueWindowSizePlugin);
app.use(createHead({
    plugins: [
        InferSeoMetaPlugin({ twitterCard: 'summary' })
    ]
}))
app.use(pinia)


if (useCookies().cookies.get('accept') == 'true')
{
    app.use(gtm);
}

app.mount('#app');
