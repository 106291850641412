import { Options, mixins } from 'vue-class-component';
import { Back } from '@element-plus/icons-vue';
import { ActiveHeadEntry, MergeHead, useHead, UseHeadInput } from '@unhead/vue'

//import 'element-ui/lib/theme-chalk/index.css';
import ApiService from '@/services/ApiService';
import { Utils, HrefLang } from '@/mixins'

@Options(
{
    components:
    {
        Back,
    },
})


export default class Chapter extends mixins(Utils, HrefLang)
{
    private lang : number = 0;
    private chapterid : string = '';
    private languageid : number = 39; // 'en'
    private langcode : string = 'en';
    private audioid : string = '';
    private loading : boolean = true;
    private hasAudio : boolean = false;
    private audioFilePath : string = '';
    private chapterModel : ChapterModel | undefined;
    private translationModel : TranslationModel | undefined;
    private bannerFileModel : FileModel | undefined;
    private audioFileModel : FileModel | undefined;
    private mediaTypeModel : MediaTypeModel | undefined;
    private languageModel : LanguageModel | undefined;
    private languageList : LanguageModel[] | undefined = [] as LanguageModel[];
    private head : void | ActiveHeadEntry<UseHeadInput<MergeHead>> = undefined;

    public async mounted()
    {
        // get chapterid from route params
        this.chapterid = String(this.$route.params.chapterid);

        // get languageid from route params
        // this.languageid = Number(this.$route.params.languageid);
        this.langcode = String(this.$route.params.lang);

        // // load languageid from local storage
        // if (localStorage.getItem("languageid") != null)
        // {
        //     let id : string | null = localStorage.getItem("languageid");
        //     if (id) this.languageid = id;
        // }

        // load all languages for selection
        this.languageList = await ApiService.getAllLanguages();

        if (this.languageList) {
            const currentLangData = this.languageList.find(lang => lang.code === this.langcode);

            this.languageid = currentLangData?.languageid || 1;
        }

        // get translation for this chapter
        this.translationModel = await ApiService.getTranslation(this.chapterid, this.languageid.toString());

        if (this.translationModel)
        {
            // load language settings
            this.languageModel = await ApiService.getLanguage(this.translationModel.languageid.toString());

            // load audio file for chapter (if exists)
            this.audioid = this.translationModel.audiofileid.toString();

            this.audioFileModel = await ApiService.getFile(this.audioid);

            if (this.audioFileModel)
            {
                this.audioFilePath = `https://www.explaintb.org/backend/backend/web/upload/${this.audioFileModel.filename}`;
                this.mediaTypeModel = await ApiService.getMediaType(this.audioFileModel.mediatypeid.toString());
                this.hasAudio = (this.mediaTypeModel != null);
            }

            // load banner file for chapter
            this.bannerFileModel = await ApiService.getBanner(this.translationModel.chapterid.toString());

            if (this.bannerFileModel)
            {
                this.loading = false;
            }


            const link = await this.generateHrefLangs(`chapter/${this.chapterid}`) as any;
            this.head = useHead({
                title: this.translationModel.title,
                htmlAttrs: {
                    lang: this.languageModel?.code ?? 'en',
                },
                meta: [{
                    name: 'description',
                    content: this.limitString(this.translationModel.content, 140),
                }],
                link,
            })
        }
    }

    public unmounted(): void {
        this.head?.dispose()
    }

    private onLanguageChange(languageid : string)
    {
        this.$router.push(`/chapter/${this.chapterid}/${languageid}`).catch(()=>{});
    }
}
