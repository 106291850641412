import { defineStore } from 'pinia'
import ApiService from '@/services/ApiService';

export const useLanguageListStore = defineStore('all-languages', {
    state: () => (
      {
        languages: [] as LanguageModel[],
        hasLanguageChanged: false,
      }
    ),
    getters: {
      languages: (state) => state.languages,
    },
    actions: {
      async loadAllLanguages() {
        if (typeof this.$state.languages === 'undefined' || this.$state.languages.length === 0) {
          this.$state.languages = await ApiService.getAllLanguages() ?? [];
        }
        return this.$state.languages
      },
      noticeLanguageChanged(changed: boolean) {
        // console.log('noticeLanguageChanged called', changed)
        this.$state.hasLanguageChanged = changed
      }
    },
  })
