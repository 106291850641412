import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  id: "main-content",
  class: "content"
}
const _hoisted_2 = ["dir"]
const _hoisted_3 = { class: "cards" }
const _hoisted_4 = { class: "card-head" }
const _hoisted_5 = ["dir"]
const _hoisted_6 = { class: "card-head" }
const _hoisted_7 = ["dir"]
const _hoisted_8 = { class: "card-head" }
const _hoisted_9 = ["dir"]
const _hoisted_10 = {
  key: 0,
  class: "foot"
}

export function render(_ctx, _cache) {
  const _component_ChatLineRound = _resolveComponent("ChatLineRound")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_RouterLink = _resolveComponent("RouterLink")
  const _component_el_col = _resolveComponent("el-col")
  const _component_VideoPlay = _resolveComponent("VideoPlay")
  const _component_Document = _resolveComponent("Document")
  const _component_el_row = _resolveComponent("el-row")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("main", _hoisted_1, [
      _createElementVNode("header", null, [
        _createElementVNode("h1", {
          class: "title",
          dir: _ctx.langModel && _ctx.langModel.align == 'left' ? 'ltr' : 'rtl'
        }, _toDisplayString(_ctx.dictWelcome), 9, _hoisted_2)
      ]),
      _createElementVNode("nav", _hoisted_3, [
        _createVNode(_component_el_row, {
          gutter: 4,
          tag: "ul"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              xs: 24,
              sm: 12,
              md: 8,
              lg: 8,
              xl: 8,
              tag: "li"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_RouterLink, {
                  class: "card-info",
                  to: `/${_ctx.lang}/info`
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_el_icon, { size: 64 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ChatLineRound)
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("p", {
                      class: "card-body",
                      dir: _ctx.langModel && _ctx.langModel.align == 'right' ? 'rtl' : 'ltr'
                    }, _toDisplayString(_ctx.dictInfos), 9, _hoisted_5)
                  ]),
                  _: 1
                }, 8, ["to"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              xs: 24,
              sm: 12,
              md: 8,
              lg: 8,
              xl: 8,
              tag: "li"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_RouterLink, {
                  class: "card-info",
                  to: `/${_ctx.lang}/video`
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_el_icon, { size: 64 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_VideoPlay)
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("p", {
                      class: "card-body",
                      dir: _ctx.langModel && _ctx.langModel.align == 'right' ? 'rtl' : 'ltr'
                    }, _toDisplayString(_ctx.dictVideos), 9, _hoisted_7)
                  ]),
                  _: 1
                }, 8, ["to"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              xs: 24,
              sm: 12,
              md: 8,
              lg: 8,
              xl: 8,
              tag: "li"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_RouterLink, {
                  class: "card-info",
                  to: `/${_ctx.lang}/handout`
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_el_icon, { size: 64 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Document)
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("p", {
                      class: "card-body",
                      dir: _ctx.langModel && _ctx.langModel.align == 'right' ? 'rtl' : 'ltr'
                    }, _toDisplayString(_ctx.dictHandout), 9, _hoisted_9)
                  ]),
                  _: 1
                }, 8, ["to"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    (_ctx.$windowWidth < _ctx.$windowHeight)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, " "))
      : _createCommentVNode("", true)
  ], 64))
}