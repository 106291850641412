import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "main-content",
  class: "content"
}
const _hoisted_2 = ["dir"]
const _hoisted_3 = {
  key: 0,
  class: "main-video"
}
const _hoisted_4 = {
  key: 1,
  class: "head"
}
const _hoisted_5 = { class: "video-list" }

export function render(_ctx, _cache) {
  const _component_VideoItem = _resolveComponent("VideoItem")
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_row = _resolveComponent("el-row")
  const _directive_loading = _resolveDirective("loading")

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("main", _hoisted_1, [
        _createElementVNode("header", {
          class: "head",
          dir: _ctx.langModel.align == 'left' ? 'ltr' : 'rtl',
          style: _normalizeStyle('text-align: '+_ctx.langModel.align)
        }, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.dictHeaderTitle), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.dictHeaderText), 1)
        ], 12, _hoisted_2),
        _cache[1] || (_cache[1] = _createStaticVNode("<h2 class=\"visually-hidden\" data-v-77f96e9c>Sponsors</h2><ul class=\"sponsors\" data-v-77f96e9c><li data-v-77f96e9c><img src=\"/img/videos_soeeg.png\" alt=\"SOEEG Logo\" data-v-77f96e9c></li><li data-v-77f96e9c><img src=\"/img/videos_kvinner.png\" alt=\"De Forente Somaliske Kvinner Logo\" data-v-77f96e9c></li><li data-v-77f96e9c><img src=\"/img/videos_vestre.png\" alt=\"VESTRE VIKEN Logo\" data-v-77f96e9c></li><li data-v-77f96e9c><img src=\"/img/videos_lhl.png\" alt=\"LHL Internasjonal Logo\" data-v-77f96e9c></li><li data-v-77f96e9c><img src=\"/img/videos_dramme.png\" alt=\"Drammen Kommune Logo\" data-v-77f96e9c></li></ul>", 2)),
        (_ctx.mainVideo)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_VideoItem, {
                type: "2",
                lang: _ctx.mainVideo.lang,
                link: _ctx.mainVideo.url
              }, null, 8, ["lang", "link"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.mainVideo)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[0] || (_cache[0] = [
              _createElementVNode("h2", { class: "subtitle" }, "Videos in other languages", -1)
            ])))
          : _createCommentVNode("", true),
        _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_5, [
          (!_ctx.loading)
            ? (_openBlock(), _createBlock(_component_el_row, {
                key: 0,
                gutter: 20
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.videoList, (item) => {
                    return (_openBlock(), _createBlock(_component_el_col, {
                      key: item.lang,
                      xs: 24,
                      sm: 12,
                      md: 6,
                      lg: 6,
                      xl: 6
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_VideoItem, {
                          type: "1",
                          lang: item.lang,
                          link: item.url
                        }, null, 8, ["lang", "link"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])), [
          [_directive_loading, _ctx.loading]
        ])
      ]))
    : _createCommentVNode("", true)
}