import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  id: "main-content",
  class: "content"
}
const _hoisted_2 = { class: "backlink-wrapper" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  controls: "",
  class: "audio-player"
}
const _hoisted_5 = ["src", "type"]
const _hoisted_6 = ["dir"]

export function render(_ctx, _cache) {
  const _component_RouterLink = _resolveComponent("RouterLink")
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_row = _resolveComponent("el-row")
  const _directive_loading = _resolveDirective("loading")

  return _withDirectives((_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_RouterLink, {
        class: "backlink",
        to: `/${_ctx.langcode}/info`
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("img", {
            src: "/img/ic_arrows_1_5.svg",
            alt: ""
          }, null, -1),
          _createElementVNode("span", null, "back to infos", -1)
        ])),
        _: 1
      }, 8, ["to"])
    ]),
    _createVNode(_component_el_row, { gutter: 40 }, {
      default: _withCtx(() => [
        (!_ctx.loading)
          ? (_openBlock(), _createBlock(_component_el_col, {
              key: 0,
              xs: 24,
              sm: 24,
              md: 12,
              lg: 12,
              class: "image"
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  class: "banner",
                  src: 'data:image/png;base64,'+_ctx.bannerFileModel.data,
                  alt: ""
                }, null, 8, _hoisted_3),
                (_ctx.hasAudio)
                  ? (_openBlock(), _createElementBlock("audio", _hoisted_4, [
                      _createElementVNode("source", {
                        src: _ctx.audioFilePath,
                        type: _ctx.mediaTypeModel.contenttype
                      }, null, 8, _hoisted_5),
                      _cache[1] || (_cache[1] = _createTextVNode(" Your browser does not support the audio element. "))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (!_ctx.loading)
          ? (_openBlock(), _createBlock(_component_el_col, {
              key: 1,
              class: _normalizeClass('text text-'+_ctx.languageModel.align),
              dir: _ctx.languageModel.align == 'right' ? 'rtl' : 'ltr',
              xs: 24,
              sm: 24,
              md: 12,
              lg: 12
            }, {
              default: _withCtx(() => [
                (!_ctx.loading)
                  ? (_openBlock(), _createElementBlock("h1", {
                      key: 0,
                      class: _normalizeClass('title-'+_ctx.languageModel.align),
                      dir: _ctx.languageModel.align == 'left' ? 'ltr' : 'rtl'
                    }, _toDisplayString(_ctx.translationModel.title), 11, _hoisted_6))
                  : _createCommentVNode("", true),
                _createElementVNode("p", null, _toDisplayString(_ctx.translationModel.content), 1)
              ]),
              _: 1
            }, 8, ["class", "dir"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ])), [
    [_directive_loading, _ctx.loading]
  ])
}