import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "menu-toggle" }


export default /*@__PURE__*/_defineComponent({
  __name: 'MenuToggle',
  props: {menuOpen: Boolean},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("span", null, null, -1)
  ])))
}
}

})