import { Options, mixins } from 'vue-class-component';
import { ActiveHeadEntry, MergeHead, useHead, UseHeadInput } from '@unhead/vue'
import { ElMessage } from 'element-plus'

//import 'element-ui/lib/theme-chalk/index.css';
import ApiService from '@/services/ApiService';
import { HrefLang } from '@/mixins'

interface ContactForm
{
    email: string;
    message: string;
}

@Options(
{
    components:
    {
    },
})


export default class Contact extends mixins(HrefLang)
{
    private lang : string = 'English';
    private langModel : LanguageModel | undefined;
    private loading : boolean = true;
    private form : ContactForm = {} as ContactForm;
    private isFormValid : boolean = false
    private isSending : boolean = false;
    private hints : string = "";
    private dictContact : string | undefined = 'Contact';
    private dictHeader : string | undefined = '';
    private dictYourEmail : string | undefined = '';
    private dictYourMessage : string | undefined = '';
    private dictTitle : string | undefined = '';
    private dictAboutHerzmann : string | undefined = '';
    private dictAboutOttoKnapp : string | undefined = '';
    private dictAboutHaecker : string | undefined = '';
    private dictAboutTrost : string | undefined = '';
    private dictAboutShaikh : string | undefined = '';
    private dictHintEmail : string | undefined = '';
    private dictHintMessageEnter : string | undefined = '';
    private dictHintMessageSuccess : string | undefined = '';
    private dictHintMessageFail : string | undefined = '';
    private head : void | ActiveHeadEntry<UseHeadInput<MergeHead>> = undefined;

    public async mounted()
    {
        // console.log('contact mounted')
        // get language from params
        this.lang = String(this.$route.params.lang);

        this.langModel = await ApiService.getLanguageByCode(this.lang);

        await this.loadDict();


        const link = await this.generateHrefLangs(`contact`) as any;

        this.head = useHead({
            title: this.dictContact,
            htmlAttrs: {
                lang: this.lang,
            },
            meta: [{
                name: 'description',
                content: this.dictHeader,
            }],
            link,
        })

        this.loading = false;
    }

    public unmounted(): void {
        this.head?.dispose()
    }

    private async loadDict()
    {
        if (this.langModel)
        {
            this.dictContact = await ApiService.getText("CONTACT", this.langModel.languageid);
            this.dictHeader = await ApiService.getText("CONTACT_HEADER", this.langModel.languageid);
            this.dictYourEmail = await ApiService.getText("YOUR_EMAIL", this.langModel.languageid);
            this.dictYourMessage = await ApiService.getText("YOUR_MESSAGE", this.langModel.languageid);
            this.dictTitle = await ApiService.getText("TEAM_HEADER", this.langModel.languageid);
            this.dictAboutHerzmann = await ApiService.getText("ABOUT_HERZMANN", this.langModel.languageid);
            this.dictAboutOttoKnapp = await ApiService.getText("ABOUT_OTTOKNAPP", this.langModel.languageid);
            this.dictAboutHaecker = await ApiService.getText("ABOUT_HAECKER", this.langModel.languageid);
            this.dictAboutTrost = await ApiService.getText("ABOUT_TROST", this.langModel.languageid);
            this.dictAboutShaikh = await ApiService.getText("ABOUT_SHAIKH", this.langModel.languageid);
            this.dictHintEmail = await ApiService.getText("HINT_EMAIL", this.langModel.languageid);
            this.dictHintMessageEnter = await ApiService.getText("HINT_ENTER_MESSAGE", this.langModel.languageid);
            this.dictHintMessageSuccess = await ApiService.getText("HINT_MESSAGE_SUCCESS", this.langModel.languageid);
            this.dictHintMessageFail = await ApiService.getText("HINT_MESSAGE_FAIL", this.langModel.languageid);
        }
    }

    private validateEmail()
    {
        if (!this.form.email || this.form.email.length <= 0) return false;

        let re : RegExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        return re.test(this.form.email);
    }

    private validateMessage()
    {
        return this.form.message != null && this.form.message.length > 0;
    }

    private validateForm()
    {
        this.hints = "";
        this.isFormValid = false;

        if (!this.validateEmail() && this.dictHintEmail)
        {
            this.hints = this.dictHintEmail;
            return;
        }

        if (!this.validateMessage() && this.dictHintMessageEnter)
        {
            this.hints = this.dictHintMessageEnter;
            return;
        }

        this.isFormValid = true;
    }

    private async SendMessage()
    {
        let ok : boolean = await ApiService.SendMessage(this.form.email, this.form.message);

        this.isSending = false;

        if (ok && this.dictHintMessageSuccess)
        {
            //this.$message.success(this.dictHintMessageSuccess);
            ElMessage(
            {
                message: this.dictHintMessageSuccess,
                type: 'success',
            });
            this.form = {} as ContactForm;
            this.isFormValid = false;
        }
        else
        {
            if (this.dictHintMessageFail)
            {
                //this.$message.error(this.dictHintMessageFail);
                ElMessage(
                {
                    message: this.dictHintMessageFail,
                    type: 'error',
                });
            }
        }
    }

    public onInput()
    {
        this.validateForm();
    }

    public onSendClick()
    {
        if (this.isFormValid)
        {
            this.isSending = true;
            this.SendMessage();
        }
    }
}
