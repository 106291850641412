import { Vue, Options } from 'vue-class-component';
//import 'element-ui/lib/theme-chalk/index.css';
import ChapterTranslation from '@/components/ChapterTranslation/ChapterTranslation.vue';
import ApiService from '@/services/ApiService';
import { useLanguageListStore } from '@/store/use-language-list-store'

@Options(
{
    components:
    {
        ChapterTranslation
    },
})


export default class Print extends Vue
{
    private loading: boolean = true;
    private lang : string = 'English';
    private langModel : LanguageModel | undefined;
    private chapterList: ChapterModel[] | undefined = [] as ChapterModel[];
    private languageList: LanguageModel[] | undefined = [] as LanguageModel[];
    private selectedChapters: number[] = [] as number[];
    private firstLanguage: number | null = null;
    private secondLanguage: number | null = null;
    private firstLanguageName : string = "";
    private secondLanguageName : string = "";

    public async mounted()
    {
        // get language from params
        this.lang = String(this.$route.params.lang);

        // load corresponding language model from db
        this.langModel = await ApiService.getLanguageByCode(this.lang);

        // this.languageList = await ApiService.getAllLanguages();
        const store = useLanguageListStore()
        this.languageList = await store.loadAllLanguages()

        // if parameters for handout are given, display translations for selected chapters
        if (this.$route.params.l1 && this.$route.params.l2 && this.$route.params.chapters)
        {
            this.firstLanguage = parseInt(String(this.$route.params.l1));
            this.secondLanguage = parseInt(String(this.$route.params.l2));
            let chapters : string[] = String(this.$route.params.chapters).split(',');
            if (chapters)
            {
                for (let c of chapters)
                {
                    this.selectedChapters.push(parseInt(c));
                }
            }

            if (this.languageList)
            {
                for (let l of this.languageList)
                {
                    if (l.languageid == this.firstLanguage)
                    {
                        this.firstLanguageName = l.native;
                    }

                    if (l.languageid == this.secondLanguage)
                    {
                        this.secondLanguageName = l.native;
                    }
                }
            }


            await this.loadChapters();
        }

        this.loading = false;
    }

    private async loadChapters()
    {
        // load all chapters for selection
        this.chapterList = await ApiService.getAllChapters();

        if (this.chapterList && this.langModel)
        {
            for (let c of this.chapterList)
            {
                let translation : TranslationModel | undefined = await ApiService.getTranslation(c.chapterid.toString(), this.langModel.languageid.toString());

                if (translation)
                {
                    c.title = translation.title;
                }
            }
        }
    }
}
