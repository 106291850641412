import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "main-content",
  class: "content"
}
const _hoisted_2 = ["dir"]
const _hoisted_3 = ["dir"]
const _hoisted_4 = { class: "bold" }
const _hoisted_5 = { class: "bold" }
const _hoisted_6 = { class: "send-button" }
const _hoisted_7 = { class: "welcome" }
const _hoisted_8 = { class: "team" }
const _hoisted_9 = { class: "team" }
const _hoisted_10 = { class: "team" }
const _hoisted_11 = { class: "team" }
const _hoisted_12 = { class: "team" }
const _hoisted_13 = { class: "team" }
const _hoisted_14 = { class: "team" }
const _hoisted_15 = { class: "team" }
const _hoisted_16 = { class: "team" }
const _hoisted_17 = { class: "team" }

export function render(_ctx, _cache) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_tag = _resolveComponent("el-tag")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_form = _resolveComponent("el-form")

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("main", _hoisted_1, [
        _createElementVNode("header", {
          class: "welcome",
          dir: _ctx.langModel.align == 'left' ? 'ltr' : 'rtl'
        }, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.dictContact), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.dictHeader), 1)
        ], 8, _hoisted_2),
        _createElementVNode("div", {
          class: "contact-form",
          dir: _ctx.langModel.align == 'left' ? 'ltr' : 'rtl'
        }, [
          _createVNode(_component_el_form, {
            ref: "contact_form",
            "label-position": "top",
            action: "#",
            model: _ctx.form,
            "label-width": "120px"
          }, {
            default: _withCtx(() => [
              _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.dictYourEmail) + ":", 1),
              _createVNode(_component_el_form_item, { prop: "email" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    autofocus: "",
                    modelValue: _ctx.form.email,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.form.email) = $event)),
                    disabled: _ctx.isSending,
                    onChange: _ctx.onInput
                  }, null, 8, ["modelValue", "disabled", "onChange"])
                ]),
                _: 1
              }),
              _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.dictYourMessage) + ":", 1),
              _createVNode(_component_el_form_item, { prop: "message" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    type: "textarea",
                    rows: "8",
                    modelValue: _ctx.form.message,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.form.message) = $event)),
                    disabled: _ctx.isSending,
                    onInput: _ctx.onInput
                  }, null, 8, ["modelValue", "disabled", "onInput"])
                ]),
                _: 1
              }),
              _createElementVNode("p", _hoisted_6, [
                (!_ctx.isFormValid && _ctx.hints.length > 0)
                  ? (_openBlock(), _createBlock(_component_el_tag, {
                      key: 0,
                      type: "danger"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.hints), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _withDirectives(_createVNode(_component_el_button, {
                  type: "success",
                  class: "right",
                  disabled: !_ctx.isFormValid || _ctx.isSending,
                  loading: _ctx.isSending,
                  onClick: _ctx.onSendClick
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" Send Message ")
                  ])),
                  _: 1
                }, 8, ["disabled", "loading", "onClick"]), [
                  [_vShow, _ctx.isFormValid]
                ])
              ])
            ]),
            _: 1
          }, 8, ["model"])
        ], 8, _hoisted_3),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("h2", null, _toDisplayString(_ctx.dictTitle), 1)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _cache[3] || (_cache[3] = _createElementVNode("h4", { class: "team" }, "Christian Herzmann", -1)),
          _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.dictAboutHerzmann), 1)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _cache[4] || (_cache[4] = _createElementVNode("h4", { class: "team" }, "Ralf Otto-Knapp", -1)),
          _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.dictAboutOttoKnapp), 1)
        ]),
        _createElementVNode("div", _hoisted_12, [
          _cache[5] || (_cache[5] = _createElementVNode("h4", { class: "team" }, "Brit Häcker", -1)),
          _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.dictAboutHaecker), 1)
        ]),
        _createElementVNode("div", _hoisted_14, [
          _cache[6] || (_cache[6] = _createElementVNode("h4", { class: "team" }, "Christine Trost", -1)),
          _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.dictAboutTrost), 1)
        ]),
        _createElementVNode("div", _hoisted_16, [
          _cache[7] || (_cache[7] = _createElementVNode("h4", { class: "team" }, "Rick Shaikh", -1)),
          _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.dictAboutShaikh), 1)
        ])
      ]))
    : _createCommentVNode("", true)
}