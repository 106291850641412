import { Options, mixins } from 'vue-class-component';
import { ActiveHeadEntry, MergeHead, useHead, UseHeadInput } from '@unhead/vue'

import 'element-plus/theme-chalk/index.css';
import ChapterItem from '@/components/ChapterItem/ChapterItem.vue';
import ApiService from '@/services/ApiService';
import { HrefLang } from '@/mixins'
@Options(
{
    components:
    {
        ChapterItem
    },
})


export default class Info extends mixins(HrefLang)
{
    private lang : string = 'en';
    private langModel : LanguageModel | undefined = {} as LanguageModel;
    private chapterModels : ChapterModel[] | undefined = [] as ChapterModel[];
    private selectedLanguageId : number = 0;
    private dictWelcome : string | undefined = 'Welcome';
    private dictInfoHeader : string | undefined = 'Explain TB offers tuberculosis information in more than 30 languages.';
    private head : void | ActiveHeadEntry<UseHeadInput<MergeHead>> = undefined;

    public async mounted()
    {
        // get language from params
        this.lang = String(this.$route.params.lang);

        // load corresponding language model from db
        this.langModel = await ApiService.getLanguageByCode(this.lang);

        // load chapters from db
        const allChapters = await ApiService.getAllChapters();

        this.chapterModels = allChapters?.filter(chapter => {
            return chapter.publish === 1;
        });

        // load text in selected language
        await this.loadDict();


        const link = await this.generateHrefLangs('info') as any;

        this.head = useHead({
            title: this.dictWelcome,
            htmlAttrs: {
                lang: this.lang,
            },
            meta: [{
                name: 'description',
                content: this.dictInfoHeader,
            }],
            link,
        })
    }

    public unmounted(): void {
        this.head?.dispose()
    }

    private async loadDict()
    {
        if (this.langModel)
        {
            this.dictWelcome = await ApiService.getText("WELCOME", this.langModel.languageid);
            this.dictInfoHeader = await ApiService.getText("INFO_HEADER", this.langModel.languageid);
        }
    }
}
