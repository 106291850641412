import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "menu-main" }
const _hoisted_2 = {
  key: 0,
  class: "link-wrapper"
}
const _hoisted_3 = {
  key: 1,
  class: "language"
}
const _hoisted_4 = ["label", "value"]
const _hoisted_5 = { class: "mobile-menu" }

export function render(_ctx, _cache) {
  const _component_RouterLink = _resolveComponent("RouterLink")
  const _component_ChatLineRound = _resolveComponent("ChatLineRound")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_VideoPlay = _resolveComponent("VideoPlay")
  const _component_Document = _resolveComponent("Document")
  const _component_Message = _resolveComponent("Message")
  const _component_MenuToggle = _resolveComponent("MenuToggle")
  const _component_el_drawer = _resolveComponent("el-drawer")
  const _component_CookieBanner = _resolveComponent("CookieBanner")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[18] || (_cache[18] = _createElementVNode("a", {
      href: "#main-content",
      class: "visually-hidden focusable skip-to-content"
    }, " Skip to content ", -1)),
    _createElementVNode("header", _hoisted_1, [
      _createVNode(_component_RouterLink, {
        to: `/${_ctx.language}`,
        "aria-label": "home page"
      }, {
        default: _withCtx(() => _cache[8] || (_cache[8] = [
          _createElementVNode("img", {
            class: "logo",
            src: "/img/explainTB_logo_blck.png",
            alt: ""
          }, null, -1)
        ])),
        _: 1
      }, 8, ["to"]),
      (_ctx.$windowWidth > 920)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_RouterLink, {
              class: "noselect",
              to: `/${_ctx.language}/info`
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, { class: "main" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChatLineRound)
                  ]),
                  _: 1
                }),
                _cache[9] || (_cache[9] = _createElementVNode("span", null, "Infos", -1))
              ]),
              _: 1
            }, 8, ["to"]),
            _createVNode(_component_RouterLink, {
              class: "noselect",
              to: `/${_ctx.language}/video`
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, { class: "main" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_VideoPlay)
                  ]),
                  _: 1
                }),
                _cache[10] || (_cache[10] = _createElementVNode("span", null, "Videos", -1))
              ]),
              _: 1
            }, 8, ["to"]),
            _createVNode(_component_RouterLink, {
              class: "noselect",
              to: `/${_ctx.language}/handout`
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, { class: "main" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Document)
                  ]),
                  _: 1
                }),
                _cache[11] || (_cache[11] = _createElementVNode("span", null, "Handout", -1))
              ]),
              _: 1
            }, 8, ["to"]),
            _createVNode(_component_RouterLink, {
              class: "noselect",
              to: `/${_ctx.language}/contact`
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_icon, { class: "main" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Message)
                  ]),
                  _: 1
                }),
                _cache[12] || (_cache[12] = _createElementVNode("span", null, "Contact", -1))
              ]),
              _: 1
            }, 8, ["to"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.showLanguage)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _cache[13] || (_cache[13] = _createElementVNode("label", {
              for: "langSelectGlobal",
              lang: "en",
              class: "visually-hidden"
            }, "Select Language", -1)),
            _withDirectives(_createElementVNode("select", {
              id: "langSelectGlobal",
              class: "lang-select",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.language) = $event)),
              onChange: _cache[1] || (_cache[1] = (...args) => (_ctx.onLanguageSelect && _ctx.onLanguageSelect(...args)))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languageList, (item) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: item.languageid,
                  label: item.name+' ('+item.native+')',
                  value: item.code
                }, _toDisplayString(item.name+' ('+item.native+')'), 9, _hoisted_4))
              }), 128))
            ], 544), [
              [_vModelSelect, _ctx.language]
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.$windowWidth <= 920)
        ? (_openBlock(), _createBlock(_component_MenuToggle, {
            key: 2,
            onClick: _cache[2] || (_cache[2] = $event => (_ctx.showMenu = !_ctx.showMenu)),
            "menu-open": _ctx.showMenu,
            "aria-label": "menu"
          }, null, 8, ["menu-open"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_el_drawer, {
      title: "",
      modelValue: _ctx.showMenu,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.showMenu) = $event)),
      direction: "rtl",
      size: (_ctx.$windowWidth > 600) ? '40%' : '100%'
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_RouterLink, {
            class: "mobile-noselect",
            to: `/${_ctx.language}/info`,
            onClick: _cache[3] || (_cache[3] = $event => (_ctx.showMenu = !_ctx.showMenu))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_icon, { class: "main-mobil" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ChatLineRound)
                ]),
                _: 1
              }),
              _cache[14] || (_cache[14] = _createElementVNode("span", null, "Infos", -1))
            ]),
            _: 1
          }, 8, ["to"]),
          _createVNode(_component_RouterLink, {
            class: "mobile-noselect",
            to: `/${_ctx.language}/video`,
            onClick: _cache[4] || (_cache[4] = $event => (_ctx.showMenu = !_ctx.showMenu))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_icon, { class: "main-mobil" }, {
                default: _withCtx(() => [
                  _createVNode(_component_VideoPlay)
                ]),
                _: 1
              }),
              _cache[15] || (_cache[15] = _createElementVNode("span", null, "Videos", -1))
            ]),
            _: 1
          }, 8, ["to"]),
          _createVNode(_component_RouterLink, {
            class: "mobile-noselect",
            to: `/${_ctx.language}/handout`,
            onClick: _cache[5] || (_cache[5] = $event => (_ctx.showMenu = !_ctx.showMenu))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_icon, { class: "main-mobil" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Document)
                ]),
                _: 1
              }),
              _cache[16] || (_cache[16] = _createElementVNode("span", null, "Handout", -1))
            ]),
            _: 1
          }, 8, ["to"]),
          _createVNode(_component_RouterLink, {
            class: "mobile-noselect",
            to: `/${_ctx.language}/contact`,
            onClick: _cache[6] || (_cache[6] = $event => (_ctx.showMenu = !_ctx.showMenu))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_icon, { class: "main-mobil" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Message)
                ]),
                _: 1
              }),
              _cache[17] || (_cache[17] = _createElementVNode("span", null, "Contact", -1))
            ]),
            _: 1
          }, 8, ["to"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "size"]),
    _createVNode(_component_CookieBanner)
  ], 64))
}