// mixins.js
import { Vue } from 'vue-class-component';
import { useLanguageListStore } from '@/store/use-language-list-store'

// You can declare mixins as the same style as components.
export class Utils extends Vue {
  limitString = (text : string, length = 140, suffix = '...') : string => (
    (text.length > 140 - 3)
        ? `${text.substring(0, length - 3)}${suffix}`
        : `${text.substring(0, length)}`
  )
}

export interface Link {
  ref: string;
  hreflang: string;
  href: string;
}

export interface HrefLangAttributes {
  hreflang: string;
  href: string;
  lang: string;
}

export type LanguageIdType = 'id' | 'number'

export class HrefLang extends Vue {

  generateHrefLangs = async (slug: string) : Promise<Link[]> => {
    const store = useLanguageListStore()
    const languages = await store.loadAllLanguages()
    return [ ...languages.map((l) =>
      ({
        ref: 'alternate',
        hreflang: l.code,
        href: `${document.location.origin}/${l.code}/${slug}/`
      })
    ),
      {
        ref: 'alternate',
        hreflang: 'x-default',
        href: `${document.location.origin}/en/${slug}/`

      }
    ]
  }
}