import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives, Transition as _Transition } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 1,
  class: "chapter-body"
}
const _hoisted_3 = {
  class: "title",
  style: {"'font-size":"min(14px,'+Math.floor(10*(90/(title ? title.length : 10)))+'px)"}
}

export function render(_ctx, _cache) {
  const _component_RouterLink = _resolveComponent("RouterLink")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createBlock(_Transition, { name: "el-fade-in-linear" }, {
    default: _withCtx(() => [
      _withDirectives((_openBlock(), _createBlock(_component_RouterLink, {
        class: "chapter",
        dir: _ctx.direction == 'left' ? 'ltr' : 'rtl',
        style: _normalizeStyle('text-align:'+_ctx.direction),
        to: `/${_ctx.langcode}/info/${_ctx.chapterid}`
      }, {
        default: _withCtx(() => [
          (_ctx.chapterLoaded)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "banner",
                src: 'data:image/png;base64,'+_ctx.fileModel.data,
                alt: ""
              }, null, 8, _hoisted_1))
            : _createCommentVNode("", true),
          (_ctx.chapterLoaded)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("h2", _hoisted_3, _toDisplayString((_ctx.title ? _ctx.title : '???')), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["dir", "style", "to"])), [
        [_directive_loading, !_ctx.chapterLoaded]
      ])
    ]),
    _: 1
  }))
}