<template>
<footer class="main-footer">
    <nav>
        <ul>
            <li>
                <router-link
                    :to="`/${$route.params.lang || 'en'}/imprint`"
                >Imprint</router-link>
            </li>

            <li>
                <router-link
                    :to="`/${$route.params.lang || 'en'}/privacy-policy`"
                >Privacy Policy</router-link>
            </li>
        </ul>
    </nav>
</footer>
</template>

<script setup lang="ts">
</script>

<style scoped>
.main-footer {
    padding: 20px;
    background-color: var(--surface-color);
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
        color: black;
    }

    ul {
        display: flex;

        li {
            margin-right: 24px;
        }
    }
}
</style>