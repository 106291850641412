import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createBlock as _createBlock, vShow as _vShow, Transition as _Transition, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = {
  id: "main-content",
  class: "content"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["dir"]
const _hoisted_4 = { class: "steps" }
const _hoisted_5 = {
  key: 0,
  class: "button-list"
}
const _hoisted_6 = {
  key: 1,
  class: "language-selection"
}
const _hoisted_7 = {
  class: "choose-lang",
  for: "handoutFirstSelect"
}
const _hoisted_8 = {
  value: null,
  disabled: ""
}
const _hoisted_9 = ["value"]
const _hoisted_10 = {
  key: 2,
  class: "language-selection"
}
const _hoisted_11 = {
  class: "choose-lang",
  for: "handoutSecondSelect"
}
const _hoisted_12 = {
  value: null,
  disabled: ""
}
const _hoisted_13 = ["value"]
const _hoisted_14 = {
  key: 3,
  class: "chapter-selection"
}
const _hoisted_15 = { class: "chapter" }
const _hoisted_16 = { class: "check-all" }
const _hoisted_17 = ["dir"]
const _hoisted_18 = ["dir"]
const _hoisted_19 = {
  key: 4,
  class: "button-create"
}
const _hoisted_20 = {
  key: 5,
  class: "chapter-list"
}
const _hoisted_21 = {
  key: 6,
  class: "button-list"
}
const _hoisted_22 = { class: "qr-code" }
const _hoisted_23 = ["src"]

export function render(_ctx, _cache) {
  const _component_el_step = _resolveComponent("el-step")
  const _component_el_steps = _resolveComponent("el-steps")
  const _component_Back = _resolveComponent("Back")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_button = _resolveComponent("el-button")
  const _component_Iphone = _resolveComponent("Iphone")
  const _component_Printer = _resolveComponent("Printer")
  const _component_el_col = _resolveComponent("el-col")
  const _component_CloseBold = _resolveComponent("CloseBold")
  const _component_el_row = _resolveComponent("el-row")
  const _component_el_checkbox = _resolveComponent("el-checkbox")
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")
  const _component_ChapterTranslation = _resolveComponent("ChapterTranslation")
  const _component_el_dialog = _resolveComponent("el-dialog")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives((_openBlock(), _createElementBlock("main", _hoisted_1, [
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("header", {
              class: "welcome",
              dir: _ctx.langModel.align == 'left' ? 'ltr' : 'rtl'
            }, [
              _createElementVNode("h1", null, _toDisplayString(_ctx.dictHeaderTitle), 1),
              _createElementVNode("h3", null, _toDisplayString(_ctx.dictHeaderText), 1)
            ], 8, _hoisted_3),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_el_steps, { active: _ctx.step }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_step, { title: _ctx.dictFirstLanguage }, null, 8, ["title"]),
                  _createVNode(_component_el_step, { title: _ctx.dictSecondLanguage }, null, 8, ["title"]),
                  _createVNode(_component_el_step, { title: _ctx.dictChooseChapters }, null, 8, ["title"]),
                  _createVNode(_component_el_step, { title: _ctx.dictDone }, null, 8, ["title"])
                ]),
                _: 1
              }, 8, ["active"])
            ]),
            (_ctx.step >= 3)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_el_button, {
                    type: "primary",
                    onClick: _ctx.onBackClick,
                    class: "handout"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_icon, { class: "left" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Back)
                        ]),
                        _: 1
                      }),
                      _createTextVNode(_toDisplayString(_ctx.dictRestart), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_el_button, {
                    type: "warning",
                    onClick: _ctx.onQRCodeClick,
                    class: "handout"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_icon, { class: "left" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Iphone)
                        ]),
                        _: 1
                      }),
                      _createTextVNode(_toDisplayString(_ctx.dictQRCode), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_el_button, {
                    type: "warning",
                    onClick: _ctx.onPrintClick,
                    class: "handout"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_icon, { class: "left" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Printer)
                        ]),
                        _: 1
                      }),
                      _createTextVNode(_toDisplayString(_ctx.dictPrintVersion), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.step < 3)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_el_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_col, {
                        xs: 24,
                        sm: 24,
                        md: 6,
                        lg: 3
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.dictFirstLanguage) + ":", 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_col, {
                        xs: 24,
                        sm: 24,
                        md: 12,
                        lg: 8
                      }, {
                        default: _withCtx(() => [
                          _withDirectives(_createElementVNode("select", {
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.firstLanguage) = $event)),
                            onChange: _cache[1] || (_cache[1] = (...args) => (_ctx.onFirstLanguageSelect && _ctx.onFirstLanguageSelect(...args))),
                            id: "handoutFirstSelect",
                            class: "select-lang"
                          }, [
                            _createElementVNode("option", _hoisted_8, _toDisplayString(_ctx.dictSelectLanguage), 1),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languageList, (item) => {
                              return (_openBlock(), _createElementBlock("option", {
                                key: item.languageid,
                                value: item.languageid
                              }, _toDisplayString(item.name+' ('+item.native+')'), 9, _hoisted_9))
                            }), 128))
                          ], 544), [
                            [_vModelSelect, _ctx.firstLanguage]
                          ]),
                          (_ctx.firstLanguage)
                            ? (_openBlock(), _createBlock(_component_el_button, {
                                key: 0,
                                class: "next",
                                type: "plain",
                                circle: "",
                                onClick: _ctx.onStepOneClick,
                                "aria-label": _ctx.dictRestart
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_icon, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_CloseBold)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }, 8, ["onClick", "aria-label"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            (_ctx.step > 0 && _ctx.step < 3)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  _createVNode(_component_el_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_col, {
                        xs: 24,
                        sm: 24,
                        md: 6,
                        lg: 3
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.dictSecondLanguage) + ":", 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_col, {
                        xs: 24,
                        sm: 24,
                        md: 12,
                        lg: 9
                      }, {
                        default: _withCtx(() => [
                          _withDirectives(_createElementVNode("select", {
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.secondLanguage) = $event)),
                            onChange: _cache[3] || (_cache[3] = (...args) => (_ctx.onSecondLanguageSelect && _ctx.onSecondLanguageSelect(...args))),
                            id: "handoutSecondSelect",
                            class: "select-lang"
                          }, [
                            _createElementVNode("option", _hoisted_12, _toDisplayString(_ctx.dictSelectLanguage), 1),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.languageList, (item) => {
                              return (_openBlock(), _createElementBlock("option", {
                                key: item.languageid,
                                value: item.languageid
                              }, _toDisplayString(item.name+' ('+item.native+')'), 9, _hoisted_13))
                            }), 128))
                          ], 544), [
                            [_vModelSelect, _ctx.secondLanguage]
                          ]),
                          (_ctx.secondLanguage)
                            ? (_openBlock(), _createBlock(_component_el_button, {
                                key: 0,
                                class: "next",
                                type: "plain",
                                circle: "",
                                onClick: _ctx.onStepTwoClick,
                                "aria-label": _ctx.dictRestart
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_icon, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_CloseBold)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }, 8, ["onClick", "aria-label"]))
                            : _createCommentVNode("", true),
                          (!_ctx.secondLanguage)
                            ? (_openBlock(), _createBlock(_component_el_button, {
                                key: 1,
                                type: "primary",
                                onClick: _ctx.onSkipSecondLanguage,
                                class: "skip"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.dictSkip), 1)
                                ]),
                                _: 1
                              }, 8, ["onClick"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            (_ctx.step == 2)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.dictChapters) + ":", 1),
                  _createElementVNode("p", _hoisted_16, [
                    _createVNode(_component_el_checkbox, {
                      indeterminate: _ctx.isIndeterminate,
                      modelValue: _ctx.checkAll,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.checkAll) = $event)),
                      onChange: _ctx.onCheckAllClick
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", {
                          class: "chapter-name",
                          dir: (_ctx.langModel.align == 'left' ? 'ltr' : 'rtl')
                        }, _toDisplayString(_ctx.dictCheckAll), 9, _hoisted_17)
                      ]),
                      _: 1
                    }, 8, ["indeterminate", "modelValue", "onChange"])
                  ]),
                  _createVNode(_component_el_checkbox_group, {
                    modelValue: _ctx.selectedChapters,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.selectedChapters) = $event)),
                    onChange: _ctx.onChapterSelectionChange
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_row, { gutter: 20 }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chapterList, (item) => {
                            return (_openBlock(), _createBlock(_component_el_col, {
                              xs: 24,
                              sm: 12,
                              md: 12,
                              lg: 12,
                              xl: 8,
                              key: item.chapterid
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(), _createBlock(_component_el_checkbox, {
                                  class: "chapter",
                                  label: item.chapterid,
                                  key: item.chapterid
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", {
                                      class: "chapter-name",
                                      dir: (_ctx.langModel.align == 'left' ? 'ltr' : 'rtl')
                                    }, _toDisplayString(item.title), 9, _hoisted_18)
                                  ]),
                                  _: 2
                                }, 1032, ["label"]))
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue", "onChange"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.step == 2)
              ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                  _createVNode(_Transition, { name: "el-fade-in-linear" }, {
                    default: _withCtx(() => [
                      _withDirectives(_createVNode(_component_el_button, {
                        type: "primary",
                        onClick: _ctx.onCreateClick,
                        dir: (_ctx.langModel.align == 'left' ? 'ltr' : 'rtl')
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.dictCreateAndShow), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick", "dir"]), [
                        [_vShow, _ctx.showButton]
                      ])
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            (_ctx.step >= 3)
              ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                  _createVNode(_component_el_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_col, {
                        span: 11,
                        class: "header"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("h2", null, _toDisplayString(_ctx.firstLanguageName), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_col, {
                        span: 12,
                        class: "header"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("h2", null, _toDisplayString(_ctx.secondLanguageName), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedChapters, (chapterid) => {
                    return (_openBlock(), _createBlock(_component_ChapterTranslation, {
                      key: chapterid,
                      chapterid: chapterid,
                      firstLangId: _ctx.firstLanguage,
                      secondLangId: _ctx.secondLanguage
                    }, null, 8, ["chapterid", "firstLangId", "secondLangId"]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (_ctx.step >= 3 && _ctx.selectedChapters.length > 3)
              ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                  _createVNode(_component_el_button, {
                    type: "primary",
                    onClick: _ctx.onBackClick,
                    class: "handout"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_icon, { class: "left" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Back)
                        ]),
                        _: 1
                      }),
                      _createTextVNode(_toDisplayString(_ctx.dictRestart), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_el_button, {
                    type: "warning",
                    onClick: _ctx.onQRCodeClick,
                    class: "handout"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_icon, { class: "left" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Iphone)
                        ]),
                        _: 1
                      }),
                      _createTextVNode(_toDisplayString(_ctx.dictQRCode), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_el_button, {
                    type: "warning",
                    onClick: _ctx.onPrintClick,
                    class: "handout"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_icon, { class: "left" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Printer)
                        ]),
                        _: 1
                      }),
                      _createTextVNode(_toDisplayString(_ctx.dictPrintVersion), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])), [
      [_directive_loading, _ctx.loading]
    ]),
    _createVNode(_component_el_dialog, {
      title: "",
      modelValue: _ctx.showQRCode,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.showQRCode) = $event)),
      width: "max(40vw, 350px)"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("img", {
            class: "qr-code",
            src: _ctx.qrURL,
            alt: "QR Code"
          }, null, 8, _hoisted_23)
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}