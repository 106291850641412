import { Options, mixins } from 'vue-class-component';
import { ActiveHeadEntry, MergeHead, useHead, UseHeadInput } from '@unhead/vue'
import { ChatLineRound, Document, Iphone, VideoPlay } from '@element-plus/icons-vue';

import ApiService from '@/services/ApiService';
import { HrefLang } from '@/mixins'

@Options(
{
    components:
    {
        ChatLineRound,
        Document,
        Iphone,
        VideoPlay
    },
})

export default class Site extends mixins(HrefLang)
{
    private lang : string = 'English';
    private langModel : LanguageModel | undefined = {} as LanguageModel;
    private loading : boolean = true;
    private dictWelcome : string | undefined = 'Welcome to ExplainTB';
    private dictInfos : string | undefined = 'Explain TB offers tuberculosis information in more than 30 languages.';
    private dictVideos : string | undefined = 'Videos provide essential tuberculosis information in multiple languages.';
    private dictApp : string | undefined = 'The ExplainTB app for Android and iPhone offers 41 chapters of patient information in 36 languages.';
    private dictHandout : string | undefined = 'Create bilingual handouts for your patients and share them across platforms.';
    private head : void | ActiveHeadEntry<UseHeadInput<MergeHead>> = undefined;


    public async mounted()
    {
        // get language from params
        this.lang = this.$route.params.lang.toString();
        this.langModel = await ApiService.getLanguageByCode(this.lang);

        // load dictionary
        await this.loadDict();


        const link = await this.generateHrefLangs('site') as any;

        this.head = useHead({
            title: this.dictWelcome,
            htmlAttrs: {
                lang: this.lang,
            },
            meta: [{
                name: 'description',
                content: this.dictInfos,
            }],
            link,
        })

        this.loading = false;
    }

    public unmounted(): void {
        this.head?.dispose()
    }

    private async loadDict()
    {
        if (this.langModel)
        {
            this.dictWelcome = await ApiService.getText("SITE_WELCOME", this.langModel.languageid);
            this.dictInfos = await ApiService.getText("SITE_CARD_INFO", this.langModel.languageid);
            this.dictVideos = await ApiService.getText("SITE_CARD_VIDEO", this.langModel.languageid);
            this.dictApp = await ApiService.getText("SITE_CARD_APP", this.langModel.languageid);
            this.dictHandout = await ApiService.getText("SITE_CARD_HANDOUT", this.langModel.languageid);
        }
    }
}
