import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "main-content",
  class: "content"
}
const _hoisted_2 = ["dir"]
const _hoisted_3 = { class: "chapter-list" }

export function render(_ctx, _cache) {
  const _component_ChapterItem = _resolveComponent("ChapterItem")
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_row = _resolveComponent("el-row")

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createElementVNode("header", {
      class: "welcome",
      dir: _ctx.langModel.align == 'left' ? 'ltr' : 'rtl'
    }, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.dictWelcome), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.dictInfoHeader), 1)
    ], 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.chapterModels != undefined)
        ? (_openBlock(), _createBlock(_component_el_row, {
            key: 0,
            gutter: 4,
            tag: "ul"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chapterModels, (item) => {
                return (_openBlock(), _createBlock(_component_el_col, {
                  xs: 24,
                  sm: 12,
                  md: 8,
                  lg: 6,
                  xl: 4,
                  key: item.chapterid,
                  tag: "li"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ChapterItem, {
                      chapterid: item.chapterid,
                      languageid: _ctx.langModel.languageid,
                      langcode: _ctx.langModel.code,
                      direction: _ctx.langModel.align
                    }, null, 8, ["chapterid", "languageid", "langcode", "direction"])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}