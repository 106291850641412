import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import NavBarTop from '@/components/NavBarTop/NavBarTop.vue';
import MainFooter from '@/components/MainFooter/MainFooter.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_RouterView = _resolveComponent("RouterView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.$route.name && _ctx.$route.name !== 'print')
      ? (_openBlock(), _createBlock(NavBarTop, { key: 0 }))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_component_RouterView, {
      key: _ctx.$route.fullPath
    })),
    (_ctx.$route.name && _ctx.$route.name !== 'print')
      ? (_openBlock(), _createBlock(MainFooter, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})