import { Options, mixins } from 'vue-class-component';
import { ActiveHeadEntry, MergeHead, useHead, UseHeadInput } from '@unhead/vue'
import 'element-plus/theme-chalk/index.css';
import { Back, CloseBold, Iphone, Printer } from '@element-plus/icons-vue';

import ChapterTranslation from '@/components/ChapterTranslation/ChapterTranslation.vue';
import ApiService from '@/services/ApiService';
import { Utils, HrefLang } from '@/mixins'
import { useLanguageListStore } from '@/store/use-language-list-store'

@Options(
{
    components:
    {
        ChapterTranslation,
        Back,
        CloseBold,
        Iphone,
        Printer
    },
})


export default class Handout extends mixins(Utils, HrefLang)
{
    private step: number = 0;
    private loading: boolean = true;
    private isIndeterminate: boolean = false;
    private checkAll: boolean = false;
    private lang : string = 'English';
    private langModel : LanguageModel | undefined;
    private languageList: LanguageModel[] | undefined = [] as LanguageModel[];
    private chapterList: ChapterModel[] | undefined = [] as ChapterModel[];
    private selectedChapters: number[] = [] as number[];
    private firstLanguage: number | null = null;
    private secondLanguage: number | null = null;
    private qrURL : string = `${ApiService.QR_PATH}qr.png`;
    private showButton: boolean = false;
    private showQRCode: boolean = false;
    private firstLanguageName : string = "";
    private secondLanguageName : string = "";
    private dictHeaderTitle : string | undefined = 'Handout';
    private dictHeaderText : string | undefined = 'Here you can create a bilingual handout.';
    private dictFirstLanguage : string | undefined = 'First Language';
    private dictSecondLanguage : string | undefined = 'Second Language';
    private dictChooseChapters : string | undefined = 'Choose Chapters';
    private dictDone : string | undefined = 'Done';
    private dictChapters : string | undefined = 'Chapters';
    private dictSelectLanguage : string | undefined = 'Select language...';
    private dictSkip : string | undefined = 'skip';
    private dictCheckAll : string | undefined = 'Check all';
    private dictCreateAndShow : string | undefined = 'Create and show';
    private dictRestart : string | undefined = 'Restart';
    private dictQRCode : string | undefined = 'QR Code';
    private dictPrintVersion : string | undefined = 'Print version';
    private head : void | ActiveHeadEntry<UseHeadInput<MergeHead>> = undefined;

    public async mounted()
    {
        this.step = 0;

        // get language from params
        this.lang = String(this.$route.params.lang);

        // load corresponding language model from db
        this.langModel = await ApiService.getLanguageByCode(this.lang);

        // load all languages for selection
        // this.languageList = await ApiService.getAllLanguages();
        const store = useLanguageListStore()
        this.languageList = await store.loadAllLanguages()

        await this.loadDict();


        // if parameters for handout are given, display translations for selected chapters
        if (this.$route.params.l1 && this.$route.params.l2 && this.$route.params.chapters)
        {
            this.firstLanguage = parseInt(String(this.$route.params.l1));
            this.secondLanguage = parseInt(String(this.$route.params.l2));
            let chapters : string[] = String(this.$route.params.chapters).split(',');

            if (chapters)
            {
                for (let c of chapters)
                {
                    this.selectedChapters.push(parseInt(c));
                }
            }

            if (this.languageList)
            {
                for (let l of this.languageList)
                {
                    if (l.languageid == this.firstLanguage)
                    {
                        this.firstLanguageName = l.native;
                    }

                    if (l.languageid == this.secondLanguage)
                    {
                        this.secondLanguageName = l.native;
                    }
                }
            }

            await this.loadChapters();

            this.step = 3;
        }


        const link = await this.generateHrefLangs('handout') as any;

        this.head = useHead({
            title: this.dictHeaderTitle,
            htmlAttrs: {
                lang: this.lang,
            },
            meta: [{
                name: 'description',
                content: this.limitString(this.dictHeaderText ?? '', 140),
            }],
            link,
        })

        this.loading = false;
    }

    public unmounted(): void {
        this.head?.dispose()
    }

    private async loadChapters()
    {
        // load all chapters for selection
        this.chapterList = await ApiService.getAllChapters();

        if (this.chapterList && this.langModel)
        {
            for (let c of this.chapterList)
            {
                let translation : TranslationModel | undefined = await ApiService.getTranslation(c.chapterid.toString(), this.langModel.languageid.toString());

                if (translation)
                {
                    c.title = translation.title;
                }
            }
        }
    }

    private async loadDict()
    {
        if (this.langModel)
        {
            this.dictHeaderTitle = await ApiService.getText("HANDOUT", this.langModel.languageid);
            this.dictHeaderText = await ApiService.getText("HANDOUT_HEADER", this.langModel.languageid);
            this.dictFirstLanguage = await ApiService.getText("FIRST_LANGUAGE", this.langModel.languageid);
            this.dictSecondLanguage = await ApiService.getText("SECOND_LANGUAGE", this.langModel.languageid);
            this.dictChooseChapters = await ApiService.getText("CHOOSE_CHAPTERS", this.langModel.languageid);
            this.dictDone = await ApiService.getText("DONE", this.langModel.languageid);
            this.dictSelectLanguage = await ApiService.getText("SELECT_LANGUAGE", this.langModel.languageid);
            this.dictChapters = await ApiService.getText("CHAPTERS", this.langModel.languageid);
            this.dictSkip = await ApiService.getText("SKIP", this.langModel.languageid);
            this.dictCheckAll = await ApiService.getText("CHECK_ALL", this.langModel.languageid);
            this.dictCreateAndShow = await ApiService.getText("CREATE_AND_SHOW", this.langModel.languageid);
            this.dictRestart = await ApiService.getText("RESTART", this.langModel.languageid);
            this.dictQRCode = await ApiService.getText("QR_CODE", this.langModel.languageid);
            this.dictPrintVersion = await ApiService.getText("PRINT_VERSION", this.langModel.languageid);
        }
    }

    private onFirstLanguageSelect(languageid: number)
    {
        this.step = 1;
    }

    private async onSecondLanguageSelect(languageid: number)
    {
        this.step = 2;

        this.loading = true;

        await this.loadChapters();

        this.loading = false;
    }

    private onChapterSelectionChange(value: any)
    {
        if (this.chapterList)
        {
            let checkedCount: number = value.length;
            this.checkAll = checkedCount === this.chapterList.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.chapterList.length;
        }

        this.showButton = (this.selectedChapters && this.selectedChapters.length > 0);
    }

    private onCheckAllClick(checked: boolean)
    {
        this.selectedChapters = [];

        if (checked && this.chapterList)
        {
            for (let chapter of this.chapterList)
            {
                this.selectedChapters.push(chapter.chapterid);
            }
        }

        this.isIndeterminate = false;
        this.showButton = (this.selectedChapters && this.selectedChapters.length > 0);
    }

    private onCreateClick()
    {
        this.step = 4;
    }

    private onBackClick()
    {
        this.step = 0;
        this.firstLanguage = null;
        this.secondLanguage = null;
    }

    private onStepOneClick()
    {
        this.step = 0;
        this.firstLanguage = null;
        this.secondLanguage = null;
        this.selectedChapters = [];
    }

    private onStepTwoClick()
    {
        this.step = 1;
        this.secondLanguage = null;
        this.selectedChapters = [];
    }

    private onPrintClick()
    {
        let href = `/${this.lang}/print/${this.firstLanguage!}/${this.secondLanguage!}/${this.selectedChapters.join(',')}`;
        window.open(href, '_blank');
    }

    private async onSkipSecondLanguage()
    {
        this.secondLanguage = null;
        this.step = 2;

        this.loading = true;
        await this.loadChapters();
        this.loading = false;
    }

    private async onQRCodeClick()
    {
        let response = await ApiService.getQRCode(this.lang, this.firstLanguage!, this.secondLanguage!, this.selectedChapters.join(','))

        if (response)
        {
            this.qrURL = `${ApiService.QR_PATH}${response}`;
        }

        this.showQRCode = true;
    }
}
