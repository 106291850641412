import { Vue, Options } from 'vue-class-component';
import { useHead } from '@unhead/vue'

@Options(
{
    components:
    {
    },
})


export default class Imprint extends Vue
{
    private loading : boolean = true;

    public async mounted()
    {
        // get language from params

        this.loading = false;
        useHead({
            title: 'Imprint',
            htmlAttrs: {
                lang: 'en',
            },
            meta: [{
                name: 'description',
                content: 'DZK – German Central Committee against tuberculosis e.V.',
            }],
            link: [
                {
                    rel: 'canonical',
                    href: '/en/imprint',
                }
            ]
        });
    }
}
