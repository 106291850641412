import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Site from '../views/Site/Site.vue'
import App from '../views/App/App.vue'
import Info from '../views/Info/Info.vue'
import Chapter from '../views/Chapter/Chapter.vue'
import Video from '../views/Video/Video.vue'
import Contact from '../views/Contact/Contact.vue'
import PrivacyPolicy from '../views/PrivacyPolicy/PrivacyPolicy.vue'
import Imprint from '../views/Imprint/Imprint.vue'
import Handout from '../views/Handout/Handout.vue'
import Print from '../views/Print/Print.vue'

const routes: Array<RouteRecordRaw> =
[
    {
        path: '/',
        redirect: '/en'
    },
    // {
    //     path: '/site',
    //     redirect: '/en'
    // },

    {
        path: '/info',
        redirect: '/en/info'
    },
    {
        path: '/:lang/info',
        name: 'info',
        component: Info,
    },
    {
        path: '/:lang/info/:chapterid',
        name: 'chapter',
        component: Chapter,
    },
    {
        path: '/video',
        redirect: '/video/en'
    },
    {
        path: '/:lang/video',
        name: 'video',
        component: Video
    },
    {
        path: '/handout',
        redirect: '/handout/en',
    },
    {
        path: '/handout/:lang',
        redirect: to => {
            return `/${to.params.lang}/handout`;
        }
    },
    {
        path: '/handout/:lang/:l1/:l2/:chapters',
        redirect: to => {
            return `/${to.params.lang}/handout/${to.params.l1}/${to.params.l2}/${to.params.chapters}`;
        }
    },
    {
        path: '/:lang/handout',
        name: 'handout',
        component: Handout,
        children:
        [
            {
                path: ':l1/:l2/:chapters',
                name: 'handout',
                component: Handout,
            }
        ]
    },
    {
        path: '/print/:lang/:l1/:l2/:chapters',
        redirect: to => {
            return `/${to.params.lang}/print/${to.params.l1}/${to.params.l2}/${to.params.chapters}`;
        }
    },
    {
        path: '/:lang/print/:l1/:l2/:chapters',
        name: 'print',
        component: Print,
    },
    {
        path: '/contact',
        redirect: '/en/contact'
    },
    {
        path: '/:lang/contact',
        name: 'contact',
        component: Contact
    },
    {
        path: '/imprint',
        redirect: '/en/imprint'
    },
    {
        path: '/:lang/imprint',
        name: 'imprint',
        component: Imprint
    },
    {
        path: '/privacy-policy',
        redirect: '/en/privacy-policy'
    },
    {
        path: '/:lang/privacy-policy',
        name: 'privacy-policy',
        component: PrivacyPolicy
    },
    {
        path: '/:lang',
        name: 'site',
        component: Site,
    },
]

const router = createRouter(
{
    history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH),
    routes
});

export default router


/*,
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "site"  '../views/AboutView.vue')
  }*/
