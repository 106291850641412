import { Vue, Options } from 'vue-class-component';
import { useHead } from '@unhead/vue'


@Options(
{
    components:
    {
    },
})


export default class Contact extends Vue
{
    private loading : boolean = true;
    private lang : number = 0;

    public async mounted()
    {
        // get language from params

        this.loading = false;
        useHead({
            title: 'Privacy Policy',
            htmlAttrs: {
                lang: 'en',
            },
            meta: [{
                name: 'description',
                content: 'DZK – German Central Committee against tuberculosis e.V.',
            }],
            link: [
                {
                    rel: 'canonical',
                    href: '/en/privacy-policy',
                }
            ]
        })
    }
}
