import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "button-bar" }
const _hoisted_2 = { class: "banner-footer" }
const _hoisted_3 = { class: "review-button" }

export function render(_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_dialog = _resolveComponent("el-dialog")
  const _component_el_col = _resolveComponent("el-col")
  const _component_el_row = _resolveComponent("el-row")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.showBanner,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.showBanner) = $event)),
      title: "We use cookies",
      width: _ctx.$windowWidth < 900 ? '90%' : '50%',
      "before-close": _ctx.onClose
    }, {
      default: _withCtx(() => [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "policy" }, [
          _createElementVNode("p", { class: "text" }, "Cookies are text files that are stored in a computer system via an Internet browser."),
          _createElementVNode("p", { class: "text" }, "Many Internet sites and servers use cookies. Many cookies contain a so-called cookie ID. A cookie ID is a unique identifier of the cookie. It consists of a character string through which Internet pages and servers can be assigned to the specific Internet browser in which the cookie was stored. This allows visited Internet sites and servers to differentiate the individual browser of the dats subject from other Internet browsers that contain other cookies. A specific Internet browser can be recognized and identified using the unique cookie ID. "),
          _createElementVNode("p", { class: "text" }, "Through the use of cookies, ExplainTB provide the users of this website with more user-friendly services that would not be possible without the cookie setting."),
          _createElementVNode("p", { class: "text" }, "By means of a cookie, the information and offers on our website can be optimized with the user in mind. Cookies allow us, as previously mentioned, to recognize our website users. The purpose of this recognition is to make it easier for users to utilize our website. The website user that uses cookies, e.g. does not have to enter access data each time the website is accessed, because this is taken over by the website, and the cookie is thus stored on the user's computer system. Another example is the cookie of a shopping cart in an online shop. The online store remembers the articles that a customer has placed in the virtual shopping cart via a cookie."),
          _createElementVNode("p", { class: "text" }, "The data subject may, at any time, prevent the setting of cookies through our website by means of a corresponding setting of the Internet browser used, and may thus permanently deny the setting of cookies. Furthermore, already set cookies may be deleted at any time via an Internet browser or other software programs. This is possible in all popular Internet browsers. If the data subject deactivates the setting of cookies in the Internet browser used, not all functions of our website may be entirely usable."),
          _createElementVNode("b", null, "Google Analytics"),
          _createElementVNode("p", { class: "text" }, "Many websites and apps use Google services to improve their content and keep it free. When they integrate our services, these sites and apps share information with Google."),
          _createElementVNode("p", { class: "text" }, "For example, when you visit a website that uses advertising services like AdSense, including analytics tools like Google Analytics, or embeds video content from YouTube, your web browser automatically sends certain information to Google. This includes the URL of the page you’re visiting and your IP address. We may also set cookies on your browser or read cookies that are already there. Apps that use Google advertising services also share information with Google, such as the name of the app and a unique identifier for advertising."),
          _createElementVNode("p", { class: "text" }, "Google uses the information shared by sites and apps to deliver our services, maintain and improve them, develop new services, measure the effectiveness of advertising, protect against fraud and abuse, and personalize content and ads you see on Google and on our partners’ sites and apps. See our Privacy Policy to learn more about how we process data for each of these purposes and our Advertising page for more about Google ads, how your information is used in the context of advertising, and how long Google stores this information."),
          _createElementVNode("p", { class: "text" }, "Our Privacy Policy explains the legal grounds Google relies upon to process your information — for example, we may process your information with your consent or to pursue legitimate interests such as providing, maintaining and improving our services to meet the needs of our users."),
          _createElementVNode("p", { class: "text" }, "Sometimes, when processing information shared with us by sites and apps, those sites and apps will ask for your consent before allowing Google to process your information. For example, a banner may appear on a site asking for consent for Google to process the information that site collects. When that happens, we will respect the purposes described in the consent you give to the site or app, rather than the legal grounds described in the Google Privacy Policy. If you want to change or withdraw your consent, you should visit the site or app in question to do so.")
        ], -1)),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_el_button, {
            type: "success",
            class: "policy-button",
            onClick: _ctx.onAcceptClick
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Accept all")
            ])),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_el_button, {
            type: "danger",
            class: "policy-button",
            onClick: _ctx.onRejectClick
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Only functional")
            ])),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "width", "before-close"]),
    _createVNode(_Transition, { name: "el-fade-in" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { class: "icon" }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createElementVNode("img", {
                    src: "/img/cookie.png",
                    class: "cookie",
                    alt: ""
                  }, null, -1)
                ])),
                _: 1
              }),
              _createVNode(_component_el_col, {
                class: "info",
                span: 14
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createElementVNode("div", { class: "info-text" }, "This site uses cookies to analyze page traffic. Please review our privacy policy.", -1)
                ])),
                _: 1
              }),
              _createVNode(_component_el_col, {
                class: "button",
                span: 2
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_el_button, { onClick: _ctx.onReviewClick }, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createTextVNode("Review")
                      ])),
                      _: 1
                    }, 8, ["onClick"])
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ], 512), [
          [_vShow, _ctx.showFooter]
        ])
      ]),
      _: 1
    })
  ], 64))
}