import { Vue, Options } from 'vue-class-component';
import CookieBanner from '@/components/CookieBanner/CookieBanner.vue';
import MenuToggle from '@/components/MenuToggle/MenuToggle.vue';
import ApiService from '../../services/ApiService';
import { ChatLineRound, Document, VideoPlay, Iphone, Message } from '@element-plus/icons-vue';
import { useLanguageListStore } from '@/store/use-language-list-store'

@Options(
{
    props:
    {
        activeMenu:
        {
            type: String,
            required: false
        }
    },
    components:
    {
        ChatLineRound,
        MenuToggle,
        Document,
        Iphone,
        VideoPlay,
        Message,
        CookieBanner
    }
})

export default class NavBarTop extends Vue
{
    public activeMenu: string | undefined;

    private environmentCode : string = '';
    private version : string = '';
    private language : string = 'en';
    private showMenu : boolean = false;
    private showLanguage : boolean = false;
    private activeIndex: string = "1";
    private languageid : number = 0;
    private languageList : LanguageModel[] | undefined = [] as LanguageModel[];

    public async mounted()
    {
        // get language from url parameter, or load from local storage
        // console.log('NavBarTop mounted')
        if (this.$route.params.lang)
        {
            this.language = this.$route.params.lang.toString();
        }

        // load all languages for selection
        const store = useLanguageListStore()
        this.languageList = await store.loadAllLanguages()

        // FIXME: check if this part is needed
        // if (!localStorage.getItem("languageid"))
        // {
        //         localStorage.setItem("languageid", "39");
        //         localStorage.setItem("languagecode", "en");
        // }
        // this.onLanguageChange(this.language, true);

        if (this.languageList)
        {
            this.showLanguage = true;
        }
    }

    private planNewRoute(language : string)
    {
        if (this.$router.currentRoute.value.name == "chapter")
        {
            this.$router.push(`/${language}/info/${this.$router.currentRoute.value.params.chapterid}`).catch(()=>{});
        }
        else if (this.$router.currentRoute.value.name == "handout")
        {
            // only reroute on language change when no translation is shown
            if (!this.$route.params.l1 && !this.$route.params.l2)
            {
                this.$router.push(`/${language}/${String(this.$router.currentRoute.value.name)}`).catch(()=>{});
            }
        }
        else if (this.$router.currentRoute.value.name == "site") {
            this.$router.push(`/${language}`).catch(()=>{});
        }
        else
        {
            console.log('set route', this.$router.currentRoute.value.name);
            this.$router.push(`/${language}/${String(this.$router.currentRoute.value.name)}`).catch(()=>{});
        }
    }

    private onLanguageSelect() {
        this.onLanguageChange(this.language);
    }

    private onLanguageChange(language : string, mounted = false)
    {
        // if (this.languageList)
        // {
        //     for (let l of this.languageList)
        //     {
        //         if (l.code == language)
        //         {
        //             localStorage.setItem("languageid", l.languageid.toString());
        //             localStorage.setItem("languagecode", l.code);
        //             break;
        //         }
        //     }
        // }
        // remove nodes, generate hreflang
        // notice language change
        const store = useLanguageListStore()
        store.noticeLanguageChanged(!mounted)

        this.planNewRoute(language);
    }
}
